import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Modal } from "react-bootstrap";
import axios from "../../../services/AxiosInstance";
import Select from "react-select";
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import LoadingScreen from "../../components/common/LoadingScreen";
import TableSlider from "../../components/common/TableSlider";

const GlobalFilter = ({ filter, setFilter }) => (
  <div>
    Search :{" "}
    <input
      className="ml-2 input-search form-control"
      value={filter || ""}
      onChange={(e) => setFilter(e.target.value)}
      style={{ width: "20%" }}
    />
  </div>
);
const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div>
      <input
        className="form-control input-search"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export const CastingActorList = ({ actors, AddIntoCasting, getData }) => {
  // coloumns
  const COLUMNS = [
    {
      Header: "Id",
      Footer: "Id",
      accessor: "id",
      Filter: ColumnFilter,
      // disableFilters: true,
    },
    {
      Header: "",
      Footer: "",
      accessor: "actor",
      id: "images",
      Cell: ({value} ) => {
        return (
          <>
          {console.log(value ,"val")}        
            {value.images !== undefined && value.images?.length !== 0 ? (
              <div className="actorList-bx">
                <div id="carouselExampleControls" className="carousel slide">
                  <div className="carousel-inner">
                    <TableSlider images={value.images} />
                  </div>
                </div>
                <div></div>
              </div>
            ) : (
              <div className="actorList-bx text-center">
                <p className="mx-auto">no image found</p>
              </div>
            )}
          </>
        );
      },
      // Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "first name",
      Footer: "first name",
      id: "fname",
      accessor: "actor",
      Filter: ColumnFilter,
      Cell: ({ value }) => value.fname,
    },
    {
      Header: "last name",
      Footer: "last name",
      id: "lname",
      accessor: "actor",
      Filter: ColumnFilter,
      Cell: ({ value }) => value.lname,
    },
    {
      Header: "email",
      Footer: "email",
      id: "email",
      accessor: "actor",
      Filter: ColumnFilter,
      Cell: ({ value }) => value.email,
    },
    {
      Header: "address",
      Footer: "address",
      id: "address",
      accessor: "actor",
      Filter: ColumnFilter,
      Cell: ({ value }) => value.address,
    },
    {
      Header: "gender",
      Footer: "gender",
      id: "gender",
      accessor: "actor",
      Filter: ColumnFilter,
      Cell: ({ value }) => value.gender,
    },
    {
      Header: "phone",
      Footer: "phone",
      id: "phone_one",
      accessor: "actor",
      Filter: ColumnFilter,
      Cell: ({ value }) => value.phone_one,
    },
    {
      Header: "Action",
      Footer: "Action",
      id: "action",
      accessor: "actors",
      Cell: ({ value, row }) => {
        console.log(actors[row.index]);

        return (
          <div className="d-flex">
            <button
              to="#"
              className="btn btn-danger shadow btn-xs sharp"
              onClick={() => deleteActorFromCasting(actors[row.index]?.id)}
            >
              <i className="fa fa-trash" />
            </button>
            <Link
              to={`/actor/detail/${actors[row.index]?.actor_id}`}
              className="btn btn-success shadow btn-xs sharp ms-1"
            >
              <i className="fas fa-eye" />
            </Link>
          </div>
        );
      },
      // Filter: ColumnFilter,
      disableFilters: true,
    },
  ];
  const { id } = useParams();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => COLUMNS, []);
  const [, setData] = useState([]);
  // const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  const [newActorList, setNewActorList] = useState([]);
  const [addActor, setAddActor] = useState();
  const [errors, setErrors] = useState(null);
  const [Loading, setLoading] = useState(false);
  const tableInstance = useTable(
    {
      columns,
      data: actors,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  // getting all actor List from API
  const actorList = async () => {
    setLoading(true);
    setErrors(null);
    try {
      const response = await axios.get("api/actors");
      setData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      if (error?.response?.status === 422) {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        const key = Object.keys(error.response.data.errors);
        const err = [];
        key.forEach((e) => {
          err.push(error.response.data.errors[e][0]);
        });
        return setErrors(err);
      }
    }
  };

  //   delete actor List
  const deleteActorFromCasting = async (id) => {
    setErrors(null);
    setLoading(true);
    try {
      const response = await axios.delete(`api/casting_list_actors/${id}`);
      if (response.data.status === 1) {
        toast.success(`✔️ deleted successfully`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        getData();
      } else {
        throw new Error("Delete failed");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response && error?.response?.status === 422) {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        const key = Object.keys(error.response.data.errors);
        const err = [];
        key.forEach((e) => {
          err.push(error.response.data.errors[e][0]);
        });
        return setErrors(err);
      }
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const actorListNotInCasting = useCallback(async () => {
    setErrors(null);
    setLoading(true);
    try {
      const response = await axios.get(
        `api/actors_doesnthave_castinglist/${id}`
      );
      console.log(response.data.data);
      setNewActorList(
        response.data.data.map((e) => ({
          value: e.id,
          label: (
            <div>
              {e.images.length > 0 && (
                <img
                  style={{ height: "50px", width: "50px" }}
                  src={e.images[0].image}
                  alt="actorimage"
                />
              )}{" "}
              <span>
                {e.fname} {e.lname}
              </span>
            </div>
          ),
        }))
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      if (error?.response?.status === 422) {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        const key = Object.keys(error.response.data.errors);
        const err = [];
        key.forEach((e) => {
          err.push(error.response.data.errors[e][0]);
        });
        return setErrors(err);
      }
    }
  }, [id]);

  useEffect(() => {
    actorList();
    actorListNotInCasting();
  }, [actorListNotInCasting]);

  return (
    <>
      {Loading ? (
        <LoadingScreen />
      ) : (
        <>
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Actor List</h4>
              {/* {!pathname.includes("contactdetails") ? (
                <button
                  onClick={() => setShow(true)}
                  className="btn btn-primary"
                >
                  Add Actors Into Casting
                </button>
              ) : null} */}
            </div>
            <div className="card-body">
              {errors && (
                <Alert variant={"danger"}>
                  <ul>
                    {errors.map((e) => (
                      <li>{e}</li>
                    ))}
                  </ul>
                </Alert>
              )}
              <div className="table-responsive">
                <GlobalFilter
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                />
                <table {...getTableProps()} className="table  display">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                            {column.canFilter ? column.render("Filter") : null}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()} className="">
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td {...cell.getCellProps()}>
                              {" "}
                              {cell.render("Cell")}{" "}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="d-flex justify-content-between">
                  <span>
                    Page{" "}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                    {""}
                  </span>
                  <span className="table-index">
                    Go to page :{" "}
                    <input
                      type="number"
                      className="ml-2"
                      defaultValue={pageIndex + 1}
                      onChange={(e) => {
                        const pageNumber = e.target.value
                          ? Number(e.target.value) - 1
                          : 0;
                        gotoPage(pageNumber);
                      }}
                    />
                  </span>
                </div>
                <div className="text-center">
                  <div className="filter-pagination  mt-3">
                    <button
                      className=" previous-button"
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      {"<<"}
                    </button>

                    <button
                      className="previous-button"
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      Previous
                    </button>
                    <button
                      className="next-button"
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      Next
                    </button>
                    <button
                      className=" next-button"
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    >
                      {">>"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal className="modal fade" show={show} onHide={setShow}>
            <div className="" role="document">
              <div className="">
                <div className="modal-header">
                  <h4 className="modal-title fs-20">Add Contact</h4>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setShow(false)}
                    data-dismiss="modal"
                  >
                    <span />
                  </button>
                </div>
                <div className="modal-body">
                  <i
                    className="flaticon-cancel-12 close"
                    data-dismiss="modal"
                  />
                  <div className="add-contact-box">
                    <div className="add-contact-content">
                      {errors && (
                        <Alert variant={"danger"}>
                          <ul id="error">
                            {errors.map((e) => (
                              <li>{e}</li>
                            ))}
                          </ul>
                        </Alert>
                      )}
                      <div className=" mb-3">
                        <label className="text-black font-w500">
                          Select Actor
                        </label>
                        <Select
                          options={newActorList}
                          onChange={(e) => setAddActor(e)}
                          isMulti
                        />
                        {newActorList.length === 0 && (
                          <p className="text-danger">No Actor Found</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      AddIntoCasting(addActor);
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};
export default CastingActorList;
