import axios from 'axios';
import { store } from '../store/store';


const axiosInstance = axios.create({
    baseURL: `https://brytalent.dev-iuh.xyz`,
});

axiosInstance.interceptors.request.use((config) => {
    const state = store.getState();
    const token = state.auth.auth.idToken;
    config.headers.Authorization = `Bearer ${token}`;
    // axios.defaults.headers.common.Authorization = `Bearer ${token}`; 
    // config.params = config.params || {};
    // config.params['auth'] = token;
    return config;
});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
   
        console.log(error.response.data.message,"from axios")
        if(error.response.data.message === "Unauthenticated.") {
            localStorage.removeItem('userDetails');
            window.location.href ='/login';
        }
        return Promise.reject(error)
    }
  );
  
export default axiosInstance;
