import React, { useEffect, useMemo, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "../../../services/AxiosInstance";



const ColumnFilter = ({ column }) => {
    const { filterValue, setFilter } = column;
    return (
      <div>
        <input
          className="form-control input-search"
          value={filterValue || ""}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
    );
  };
const ContactActorList = () => {

 // coloumns
 const COLUMNS = [
    {
      Header: "Id",
      Footer: "Id",
      accessor: "id",
      Filter: ColumnFilter,
      //disableFilters: true,
    },
    {
      Header: "client name",
      Footer: "client name",
      accessor: "casting_list",
      id:"actor_firstname",
      Cell: ({ value }) => {
        return value?.client_name
      },
      Filter: ColumnFilter,
    },
    {
      Header: "director",
      Footer: "director",
      accessor: "casting_list",
      id:"actor_lastname",
      Cell: ({ value }) => {
        return value?.casting_director
      },
      Filter: ColumnFilter,
    },
    {
      Header: "title",
      Footer: "title",
      accessor: "casting_list",
      id:"title",
      Cell: ({ value }) => {
        return value?.title
      },
      Filter: ColumnFilter,
    },
    {
      Header: "description",
      Footer: "description",
      accessor: "description",
      id:"description",
 
    },
    {
      Header: "summary",
      Footer: "summary",
      accessor: "casting_list",
      id:"summary",
      Cell: ({ value }) => {
        return value?.summary;
      },
      Filter: ColumnFilter,
    },
    {
      Header: "start date",
      Footer: "start date",
      accessor: "casting_list",
      id:"start_date",
      Cell: ({ value }) => {
        return value?.start_date;
      },
      Filter: ColumnFilter,
      // disableFilters: true,
    },
    {
      Header: "end date",
      Footer: "end date",
      accessor: "casting_list",
      id: "end_date",
      Cell: ({ value }) => {
        return value?.end_date;
      },
      Filter: ColumnFilter,
      // disableFilters: true,
    },
    {
      Header: "Action",
      Footer: "Action",
      id: "action",
      accessor: "id",
      Cell: ({ value }) => (
        <div className="d-flex">
          <Link
            to={"/contactdetails/" + value}
            className="btn btn-success shadow btn-xs sharp ms-1"
          >
            <i className="fas fa-eye"></i>
          </Link>
        </div>
      ),
      // Filter: ColumnFilter,
      disableFilters: true,
    },
  ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const columns = useMemo(() => COLUMNS, []);
    const [data, setData] = useState([]);
    const [errors, setErrors] = useState(null);
    const tableInstance = useTable(
        {
          columns,
          data,
          initialState: { pageIndex: 0 },
        },
        useFilters,
        useGlobalFilter,
        usePagination
      );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
      } = tableInstance;

      const {pageIndex } = state; 


        // getting all actor List from API
  const actorList = async () => {
    setErrors(null);
    try {
      const response = await axios.get("/api/admin/contact_actors");
        const newData = response?.data?.data.filter((e) => e.casting_list !== null);
      console.log(newData , "newData");
      setData(newData);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      if (error?.response?.status === 422) {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        const key = Object.keys(error.response.data.errors);
        const err = [];
        key.forEach((e) => {
          err.push(error.response.data.errors[e][0]);
        });
        return setErrors(err);
      }
    }
  };
  useEffect(() => {
    actorList();
  }, []);

  return (
    <>
      <PageTitle activeMenu="Contact Actor" motherMenu="Contact Actor List" />
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Contact Actor List</h4>
        </div>
        <div className="card-body">
          {errors && (
            <Alert variant={"danger"}>
              <ul>
                {errors.map((e) => (
                  <li>{e}</li>
                ))}
              </ul>
            </Alert>
          )}
          <table {...getTableProps()} className="table  display">
          <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex justify-content-between">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
                {""}
              </span>
              <span className="table-index">
                Go to page :{" "}
                <input
                  type="number"
                  className="ml-2"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(pageNumber);
                  }}
                />
              </span>
            </div>
            <div className="text-center">
              <div className="filter-pagination  mt-3">
                <button
                  className=" previous-button"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </button>

                <button
                  className="previous-button"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  Previous
                </button>
                <button
                  className="next-button"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  Next
                </button>
                <button
                  className=" next-button"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>
              </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default ContactActorList;
