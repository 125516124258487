import React, { Suspense, lazy, useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";
import "react-input-range/lib/css/index.css";
import "rc-slider/assets/index.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import Setting from "./layouts/Setting";
// 
import { ThemeContext } from "../context/ThemeContext";
import ContactActorList from "./pages/ContactActor/ContactActorList";
import ContactDetails from "./pages/ContactActor/ContactDetails";
// import LoadingScreen from "./components/common/LoadingScreen";
/// Dashboard
const Home =  lazy(()=>import("./pages/Home.js"))
// casting list
const CastingList =  lazy(()=>import("./pages/CastingList/Castinglist"));
const AddCasting =  lazy(()=>import("./pages/CastingList/AddCasting"));
const UpdateCasting =  lazy(()=>import("./pages/CastingList/UpdateCasting"));
const DetailCasting =  lazy(()=>import("./pages/CastingList/CastingDetails"));

// actor
const ActorList =  lazy(()=>import("./pages/Actor/ActorList"));
const AddActor =  lazy(()=>import("./pages/Actor/AddActor"));
const UpdateActor =  lazy(()=>import("./pages/Actor/UpdateActor"));
const ActorDetail =  lazy(()=>import("./pages/Actor/ActorDetail"));

// User
const UserList =  lazy(()=>import("./pages/User/UserList"));
const AddUser =  lazy(()=>import("./pages/User/AddUser"));
const UpdateUser =  lazy(()=>import("./pages/User/UpdateUser"));
const UserDetail =  lazy(()=>import("./pages/User/UserDetail"));

// talent agency
const TalentAgencyList =  lazy(()=>import("./pages/TalentAgency/List"));
const AddTalentAgency =  lazy(()=>import("./pages/TalentAgency/Add"));
const UpdateTalentAgency =  lazy(()=>import("./pages/TalentAgency/Update"));
const TalentAgencyDetail =  lazy(()=>import("./pages/TalentAgency/Detail"));

/// Pages

const Error404 =  lazy(()=>import("./pages/Error404"))

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    // casting
    { url: "casting", component: CastingList },
    { url: "casting/add", component: AddCasting },
    { url: "casting/update/:id", component: UpdateCasting },
    { url: "casting/detail/:id", component: DetailCasting },

    // actor
    { url: "actor", component: ActorList },
    { url: "actor/add", component: AddActor },
    { url: "actor/update/:id", component: UpdateActor },
    { url: "actor/detail/:id", component: ActorDetail },

    // user
    { url: "User", component: UserList },
    { url: "User/add", component: AddUser },
    { url: "User/update/:id", component: UpdateUser },
    { url: "User/detail/:id", component: UserDetail },
    // user
    { url: "TalentAgency", component: TalentAgencyList },
    { url: "TalentAgency/add", component: AddTalentAgency },
    { url: "TalentAgency/update/:id", component: UpdateTalentAgency },
    { url: "TalentAgency/detail/:id", component: TalentAgencyDetail },
    //contact actor
    { url: "contactactor", component: ContactActorList },
    { url: "contactdetails/:id", component: ContactDetails },
    { url: "*", component: Error404 },
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  const pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "vh-100"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
                  <Suspense >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
            </Suspense>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <Setting />
    </>
  );
};

export default Markup;
