import React, {  useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
// import logo from "../../../images/logo.png";

import { ThemeContext } from "../../../context/ThemeContext";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const {  openMenuToggle } =
    useContext(ThemeContext);
  return (
    <div className="nav-header">
      <Link to="/dashboard" className="brand-logo">
        {/* {background.value === "dark" || navigationHader !== "color_1" ? ( */}
          {/* <> */}
            <h1>Bry Talent</h1>
            {/* <img
              src={logo}
              className="logo-abbr mt-5 me-3"
              style={{ maxWidth: "4rem" }}
              alt="logo"
            />

        
          </>
        ) : (
          <>
            <img
              src={logo}
              className="logo-abbr mt-5 me-3"
              style={{ maxWidth: "4rem" }}
              alt="logo"
            />

        
          </>
        )} */}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line" />
          <span className="line" />
          <span className="line" />
        </div>
      </div>
    </div>
  );
};

export default NavHader;
