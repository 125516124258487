import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";
import axiosInstance from "../../../services/AxiosInstance";
import CastingActorList from "../CastingList/CastingActorList";
import LoadingScreen from "../../components/common/LoadingScreen";

const ContactDetail = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  // get casting details by id
  const getContactById = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`api/contact_actors/${id}`);
      console.log(response, "data");
      setData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [id]);

  useEffect(() => {
    getContactById();
  }, [getContactById]);
  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <div className="row">
            <div className="col-xl-12 col-xxl-12">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h4 className="card-title">{data?.casting_list?.title}</h4>
                </div>
                <div className="card-body" />
                <div className="card-body d-flex pt-0 align-items-center flex-wrap">
                  <div className="d-sm-flex d-block align-items-center">
                    <div className="me-5 mb-sm-0 mb-3">
                      <p className="mb-2">
                        <i className="far fa-user scale3 me-3" />
                        casting director
                      </p>
                      <h4 className="mb-0 card-title">
                        {data?.casting_list?.casting_director}
                      </h4>
                    </div>
                    <div className="me-5 mb-sm-0 mb-3">
                      <p className="mb-2">
                        <i className="fas fa-eye scale3 me-3" />
                        client name
                      </p>
                      <h4 className="mb-0 card-title">
                        {data?.casting_list?.client_name}
                      </h4>
                    </div>
                    <div className="me-5 mb-sm-0 mb-3">
                      <p className="mb-2">
                        <i className="fa fa-calendar-minus scale3 me-3" />
                        start date - end date
                      </p>
                      {data?.casting_list?.start_date}-{" "}
                      {data?.casting_list?.end_date}
                    </div>
                  </div>
                  <hr style={{ width: "100%", height: "3px" }} />
                  <div className="d-sm-flex d-block align-items-center">
                    <div className="me-5 mb-sm-0 mb-3">
                      <h4 className="mb-0 card-title">description</h4>
                      <p className="mb-2">{data?.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {data &&
              data.casting_list &&
              data.casting_list.actors.length > 0 && (
                <CastingActorList actors={data.casting_list.actors} />
              )}
          </div>
        </>
      )}
    </>
  );
};
export default ContactDetail;
