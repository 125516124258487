/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";

/// Image
// import profile from "../../../images/profile/pic1.jpg";
// import plus from "../../../images/plus.png";

class MM extends Component {
	  componentDidMount() {
		this.$el = this.el;
		this.mm = new Metismenu(this.$el);
	  }

	  componentWillUnmount() {
	  }

	render() {
		return (
			<div className="mm-wrapper">
				<ul className="metismenu" ref={(el) => (this.el = el)}>
					{this.props.children}
				</ul>
			</div>
		);
	}
}

const SideBar = () => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);
  useEffect(() => {
    const btn = document.querySelector(".nav-control");
    const aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
	
	// sidebar icon Heart blast
	// var handleheartBlast = document.querySelector('.heart');
  //       function heartBlast() {
  //           return handleheartBlast.classList.toggle("heart-blast");
  //       }
  //       handleheartBlast.addEventListener('click', heartBlast);
	
  }, []);
  const scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  const fullPath = window.location.pathname.split("/");
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  const deshBoard = [
      "dashboard",
      "dashboard-dark",
      "guest-list",
      "guest-detail",
      "concierge",
      "room-list",
      "reviews",
      "task",
    ];
 

  return (
    <div
      className={`deznav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="deznav-scroll mt-5">
        <MM className="metismenu" id="menu">
          <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
            <Link className="" to="/dashboard" >
              <i className="flaticon-025-dashboard" />
              <span className="nav-text">Dashboard</span>
            </Link>
            {/* <ul >
              <li><Link className={`${path === "" ? "mm-active" : "dashboard"}`} to="/dashboard"> Dashboard Light</Link></li>
				<li>
					<Link className={`${path === "dashboard-dark" ? "mm-active" : ""}`} to="/dashboard-dark"> 
						Dashboard Dark
					</Link>
				</li>
				
				<li><Link className={`${path === "guest-list" ? "mm-active" : ""}`} to="/guest-list">Guest</Link></li>
				<li><Link className={`${path === "guest-detail" ? "mm-active" : ""}`} to="/guest-detail">Guest Detail</Link></li>
				<li><Link className={`${path === "concierge" ? "mm-active" : ""}`} to="/concierge">Concierge</Link></li>
				<li><Link className={`${path === "room-list" ? "mm-active" : ""}`} to="/room-list">Room</Link></li>
				<li><Link className={`${path === "reviews" ? "mm-active" : ""}`} to="/reviews">Reviews</Link></li>
				<li><Link className={`${path === "task" ? "mm-active" : ""}`} to="/task">Task</Link></li>
            </ul> */}
          </li>
          <li className={`${fullPath.includes("casting") ? "mm-active" : ""}`}>
            <Link className="" to="/casting" >
              <i className="flaticon-050-info" />
              <span className="nav-text">Casting</span>
            </Link>
            
          </li>
          <li className={`${fullPath.includes("actor") ? "mm-active" : ""}`}>
            <Link className="" to="/actor" >
              <i className="fa fa-user" />
              <span className="nav-text">Actor</span>
            </Link>
            
          </li>
          <li className={`${fullPath.includes("user") ? "mm-active" : ""}`}>
            <Link className="" to="/user" >
              <i className="fa fa-users" />
              <span className="nav-text">Agency</span>
            </Link>
          </li>
          {/* <li className={`${fullPath.includes("TalentAgency") ? "mm-active" : ""}`}>
            <Link className="" to="/TalentAgency" >
              <i className="fa fa-users" />
              <span className="nav-text">Talent Agency</span>
            </Link>
          </li> */}
          <li className={`${fullPath.includes("contactactor") ? "mm-active" : ""}`}>
            <Link className="" to="/contactactor" >
              <i className="fa fa-phone"></i>
              <span className="nav-text">Inquires</span>
            </Link>
            
          </li>
        </MM>
		
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
